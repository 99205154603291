import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filenameWithoutExtension',
  standalone: true,
})
export class FilenameWithoutExtensionPipe implements PipeTransform {
  transform(filename: string): string {
    if (!filename) return '';
    return filename.split('.').slice(0, -1).join('.');
  }
}
