import { Component, OnInit, inject, input, output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import * as fromSeriousSystem from '@serious-system';
import * as fromAssistants from './assistants';
import { NewConvoClickedEvent } from './chats.page';
import { ThreadsListingMobileResponsive } from './components/threads-listing.mobile.responsive';
import { ChatActions } from './store/chats.actions';
import { ChatsState, chatsFeature } from './store/chats.reducer';

@Component({
  selector: 'squadbox-chats-mobile',
  imports: [
    RouterModule,
    TranslateModule,
    fromSeriousSystem.UseUIBasicIconDirective,
    fromSeriousSystem.UseNavigationIconDirective,
    fromSeriousSystem.UseArrowIconDirective,
    fromSeriousSystem.IconButtonDirective,
    fromSeriousSystem.ItemsNavigationComponent,
    ThreadsListingMobileResponsive,
  ],
  template: `
    <div
      class="grid grid-rows-[auto_1fr] h-svh w-screen overflow-hidden"
      [class.bg-gradient-b-diagonal]="isThreadsListingContainerOpened()"
    >
      <!-- TopBar Home -->
      @if (isThreadsListingContainerOpened()) {
      <div
        class="flex flex-row justify-between items-center py-2 px-4 text-neutral-50 sticky top-0 z-10"
      >
        <div class="flex flex-row items-center gap-4 dark">
          <button
            sdIconButton
            variant="icon"
            color="neutral"
            size="md"
            (click)="toggleSideContainer()"
          >
            <svg sdUseUIBasicIcon="menu"></svg>
          </button>

          <span class="typo-subheader font-semibold">
            {{ 'HOME.TITLE' | translate }}
          </span>
        </div>
      </div>

      <!-- FAB new-convo -->
      <button
        sdIconButton
        variant="filled"
        color="primary"
        size="lg"
        class="fixed bottom-4 right-4 z-20 shadow-md"
        (click)="toggleThreadsListingContainer()"
        (click)="newConvoClicked.emit()"
      >
        <svg sdUseNavigationIcon="new-chat"></svg>
      </button>

      } @else {
      <!-- ConversationPage / AssistantsPage -->
      <router-outlet class="h-full overflow-hidden"></router-outlet>
      }

      <squadbox-threads-listing-mobile
        [isThreadsListingContainerOpened]="isThreadsListingContainerOpened()"
        [itemNavigations]="itemNavigations()"
        [assistants]="activeAssistants()"
        (exploreMyAssistantsClicked)="handleExploreMyAssistantsClicked()"
        (itemClicked)="handleItemClicked($event)"
        (assistantClicked)="handleAssistantClicked($event)"
      ></squadbox-threads-listing-mobile>
    </div>
  `,
  styles: [``],
})
export class ChatsMobileResponsive implements OnInit {
  private readonly chatsStore = inject<Store<ChatsState>>(Store);
  private readonly assistantsStore =
    inject<Store<fromAssistants.AssistantsState>>(Store);

  public readonly itemNavigations =
    input.required<fromSeriousSystem.ItemNavigation[]>();

  public readonly newConvoClicked = output<NewConvoClickedEvent>();
  public readonly itemClicked = output<fromSeriousSystem.ItemNavigation>();

  public readonly activeAssistants = this.assistantsStore.selectSignal(
    fromAssistants.assistantsFeature.selectActiveAssistants
  );
  public readonly isSideContainerOpened = this.chatsStore.selectSignal(
    chatsFeature.selectIsSideContainerOpened
  );
  public readonly isThreadsListingContainerOpened =
    this.chatsStore.selectSignal(
      chatsFeature.selectIsThreadsListingContainerOpened
    );

  ngOnInit(): void {
    this.chatsStore.dispatch(ChatActions.openThreadsListingContainer());
  }

  public toggleSideContainer() {
    if (this.isSideContainerOpened()) {
      this.chatsStore.dispatch(ChatActions.closeSideContainer());
    } else {
      this.chatsStore.dispatch(ChatActions.openSideContainer());
    }
  }

  public toggleThreadsListingContainer() {
    if (this.isThreadsListingContainerOpened()) {
      this.chatsStore.dispatch(ChatActions.closeThreadsListingContainer());
    } else {
      this.chatsStore.dispatch(ChatActions.openThreadsListingContainer());
    }
  }

  public handleExploreMyAssistantsClicked() {
    this.chatsStore.dispatch(ChatActions.closeThreadsListingContainer());
  }

  public handleAssistantClicked(event: NewConvoClickedEvent) {
    this.chatsStore.dispatch(ChatActions.closeThreadsListingContainer());
    this.newConvoClicked.emit(event);
  }

  public handleItemClicked(item: fromSeriousSystem.ItemNavigation) {
    this.chatsStore.dispatch(ChatActions.closeThreadsListingContainer());
    this.itemClicked.emit(item);
  }
}
