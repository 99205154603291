import * as fromGenerated from '../../../_generated';

export const getUniqueDocuments = (
  documentsChunks: fromGenerated.DocumentChunkView[] | undefined
): fromGenerated.DocumentView[] => {
  if (!documentsChunks?.length) {
    return [];
  }

  const documents = new Map<string, fromGenerated.DocumentView>();

  documentsChunks.forEach(({ document }) => {
    if (document && !documents.has(document.uuid)) {
      documents.set(document.uuid, document);
    }
  });

  return Array.from(documents.values());
};
