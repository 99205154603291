import { NgClass } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import * as fromSeriousSystem from '@serious-system';
import { MarkdownModule } from 'ngx-markdown';
import * as fromGenerated from '../../../_generated';
import * as fromFeatureFlags from '../../../feature-flags';
import { fromFileUploadHelpers } from '../../assistants';
import * as fromShared from '../../shared';

@Component({
  selector: 'squadbox-message',
  imports: [
    NgClass,
    fromSeriousSystem.UseAiIconDirective,
    MarkdownModule,
    fromSeriousSystem.FileChipComponent,
    fromFeatureFlags.FeatureFlagsDirective,
    fromShared.FilenameWithoutExtensionPipe,
  ],
  styles: [
    `
      /**
    * ==============================================
    * Dot Flashing
    * ==============================================
    */
      .dot-flashing {
        position: relative;
        width: 0.375rem;
        height: 0.375rem;
        border-radius: 0.1875rem;
        background-color: theme('colors.neutral.200');
        color: theme('colors.neutral.200');
        animation: dot-flashing 1s infinite linear alternate;
        animation-delay: 0.5s;
      }
      .dot-flashing::before,
      .dot-flashing::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
      }
      .dot-flashing::before {
        left: -0.5rem;
        width: 0.375rem;
        height: 0.375rem;
        border-radius: 0.1875rem;
        background-color: theme('colors.neutral.200');
        color: theme('colors.neutral.200');
        animation: dot-flashing 1s infinite alternate;
        animation-delay: 0s;
      }
      .dot-flashing::after {
        left: 0.5rem;
        width: 0.375rem;
        height: 0.375rem;
        border-radius: 0.1875rem;
        background-color: theme('colors.neutral.200');
        color: theme('colors.neutral.200');
        animation: dot-flashing 1s infinite alternate;
        animation-delay: 1s;
      }

      @keyframes dot-flashing {
        0% {
          background-color: theme('colors.neutral.200');
        }
        50% {
          background-color: theme('colors.neutral.300');
        }
        100% {
          background-color: theme('colors.neutral.400');
        }
      }
    `,
  ],
  template: `
    <div class="flex gap-1 tablet-landscape:gap-3">
      @if (!isFromUser() && !isLoading()) {
      <div
        class="flex items-center justify-center rounded-full mt-2 size-6 tablet-landscape:size-8 bg-gradient-a-diagonal flex-shrink-0"
      >
        <svg class="text-shades-white size-4 mr-0.5" sdUseAiIcon="stars"></svg>
      </div>
      } @if (isThinking()) {
      <div
        class="flex justify-start items-center relative overflow-hidden tablet-landscape:mt-1 p-4"
      >
        <div class="dot-flashing"></div>
      </div>
      } @else {
      <div
        class="rounded-2xl typo-p2"
        [ngClass]="{
          'bg-primary-500/10': isFromUser(),
          'bg-primary-200/20 animate-pulse': isLoading(),
          'p-2': !isFromUser(),
          'px-4 py-3 max-w-70 tablet-landscape:max-w-110': isFromUser(),
          'p-2 prose': !isFromUser(),
        }"
      >
        <!-- LOADING MESSAGE -->
        @if (isLoading()) {
        <div [class.text-transparent]="isLoading()">
          {{ messageContent() }}
        </div>
        <!-- USER MESSAGE -->
        } @else if (isFromUser()) {
        {{ messageContent() }}
        <!-- ASSISTANT MESSAGE -->
        } @else {
        <markdown [data]="messageContent()"></markdown>
        <!-- DOCUMENT CHIPS -->
        <div
          [squadboxFeatureFlag]="'ff-display-document-sources'"
          class="flex flex-wrap pt-3.5 gap-2"
        >
          @if (uniqueDocuments(); as messageDocuments) { @for (document of
          messageDocuments; track document.uuid) {
          <sd-file-chip
            [title]="document.filename | filenameWithoutExtension"
            [fileType]="fromFileUploadHelpers.getFileType(document.extension)"
            [isLoading]="false"
            [isDisabled]="!!document.deletedAt"
          ></sd-file-chip>
          } }
        </div>
        }
      </div>
      }
    </div>
  `,
})
export class MessageComponent {
  public readonly fromFileUploadHelpers = fromFileUploadHelpers;
  public readonly messageContent = input.required<string>();
  public readonly documentsChunks = input<fromGenerated.DocumentChunkView[]>();
  public readonly isFromUser = input.required<boolean>();
  public readonly isThinking = input<boolean>(false);
  public readonly isLoading = input<boolean>(false);

  public readonly uniqueDocuments = computed(() =>
    fromShared.getUniqueDocuments(this.documentsChunks())
  );
}
