import { Component, input, output } from '@angular/core';
import * as fromSeriousSystem from '@serious-system';

@Component({
  selector: 'squadbox-top-bar-mobile',
  imports: [
    fromSeriousSystem.IconButtonDirective,
    fromSeriousSystem.UseArrowIconDirective,
    fromSeriousSystem.UseUIBasicIconDirective,
  ],
  template: `
    <div
      class="grid grid-cols-[min-content_auto_min(48px)] gap-4 justify-between items-center p-2 z-10 w-full border-b border-neutral-200"
    >
      <button
        sdIconButton
        variant="icon"
        color="neutral"
        size="md"
        (click)="chevronLeftButtonClicked.emit()"
      >
        <svg sdUseArrowIcon="chevron-left"></svg>
      </button>

      <span
        class="typo-p2 font-semibold text-nowrap overflow-ellipsis overflow-hidden"
      >
        {{ title() }}
      </span>
      @if (!hideMoreOptionsButton()) {
      <button sdIconButton variant="icon" color="neutral" size="md">
        <svg
          sdUseUIBasicIcon="more-horizontal"
          (click)="moreOptionsClicked.emit()"
        ></svg>
      </button>
      } @else {
      <div></div>
      }
    </div>
  `,
})
export class TopBarMobileResponsive {
  public readonly title = input<string>();
  public readonly hideMoreOptionsButton = input<boolean>(true);

  public readonly chevronLeftButtonClicked = output<void>();
  public readonly moreOptionsClicked = output<void>();
}
