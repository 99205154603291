import { Component, input, output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import * as fromSeriousSystem from '@serious-system';
import * as fromGenerated from '../../_generated';
import { NewConvoClickedEvent } from '../chats.page';

@Component({
  selector: 'squadbox-threads-listing-mobile',
  imports: [
    fromSeriousSystem.UseNavigationIconDirective,
    fromSeriousSystem.ItemsNavigationComponent,
    TranslateModule,
    RouterModule,
  ],
  template: `
    <div class="relative w-full h-full">
      <div
        class="absolute inset-0 flex items-end justify-center transition-transform duration-500 ease-in-out overflow-hidden"
        [class.translate-y-full]="!isThreadsListingContainerOpened()"
        [class.translate-y-0]="isThreadsListingContainerOpened()"
      >
        <div
          class="w-full h-full shadow-lg rounded-t-3xl bg-shades-white flex flex-col pt-12 pb px-4"
        >
          <!-- Threads Listing -->
          <div class="flex flex-col gap-8 overflow-y-auto">
            <!-- Chats Main Navigation Items (assistants, ...) -->
            <div class="flex flex-col gap-1">
              @for (assistant of assistants(); track assistant.uuid) {
              <a
                class="main-navigation-item typo-p2 py-3"
                (click)="
                  assistantClicked.emit({ assistantUuid: assistant.uuid })
                "
              >
                <div
                  class="
                flex items-center justify-center
                outline outline-primary-500/15  outline-offset-2 rounded-full
                bg-primary-500/5
              "
                >
                  <img src="{{ assistant.logo }}" />
                </div>
                <span class="font-semibold">{{ assistant.name }}</span>
              </a>
              }

              <!-- Explore My Assistants -->
              <a
                class="main-navigation-item typo-p2 py-3"
                routerLink="/chats/assistants"
                (click)="exploreMyAssistantsClicked.emit()"
              >
                <svg sdUseNavigationIcon="dashboard"></svg>
                <span>{{
                  'MAIN_NAVIGATION_ITEMS.ASSISTANTS' | translate
                }}</span>
              </a>
            </div>

            <!-- Chats Navigation Items (chats) -->
            <sd-items-navigation
              [isMobile]="true"
              [items]="itemNavigations()"
              (itemClicked)="itemClicked.emit($event)"
            ></sd-items-navigation>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: ``,
})
export class ThreadsListingMobileResponsive {
  public readonly assistants = input.required<fromGenerated.AssistantView[]>();
  public readonly isThreadsListingContainerOpened = input.required<boolean>();
  public readonly itemNavigations =
    input.required<fromSeriousSystem.ItemNavigation[]>();

  public readonly exploreMyAssistantsClicked = output();
  public readonly itemClicked = output<fromSeriousSystem.ItemNavigation>();
  public readonly assistantClicked = output<NewConvoClickedEvent>();
}
